(($) => {
    $(document).ready(() => {
        let error = $('#js-pro-profile-validation-sms-code-error');
        let form = $('#js-pro-profile-form');
        let modal = $('#js-confirm-phone-modal');
        let phone = $('#update_contact_cellphone').val();
        let submittedPhone;

        function isUpdatedPhone () {
            submittedPhone = $('#update_contact_cellphone').val()
            return phone !== submittedPhone;
        }

        function initModal () {
            error.hide();

            modal.off('shown.bs.modal')
            modal.on('shown.bs.modal', () => {

                let phoneConfirmationUrl = $('input[data-confirmation-url]').attr('data-confirmation-url');

                $.ajax({
                    async: false,
                    method: 'GET',
                    url: phoneConfirmationUrl,
                    data: { phone : submittedPhone },
                    success: () => {
                        listenConfirmation();
                    }
                });
            })
        }

        function listenConfirmation() {
            let confirmationCodeSubmitted;
            let confirmSmsCodeInput = $('#js-pro-profile-sms-code');
            let codeContainer = $('#js-pro-profile-validation-sms-code-container');
            let confirmSmsButton = $('#js-pro-profile-confirm-sms-button')

            modal.on('hidden.bs.modal', () => {
                location.reload();
            })

            confirmSmsCodeInput.focus();
            confirmSmsButton.on('click', () => {

                error.hide();
                confirmationCodeSubmitted = confirmSmsCodeInput.val();

                $.ajax({
                    async: false,
                    method: 'GET',
                    url: confirmSmsButton.attr('data-url'),
                    data: { confirmationCode : confirmationCodeSubmitted },
                    success: (results) => {
                        if (results.hasOwnProperty('success')) {
                            if (results.success) {
                                form.off('submit');
                                form.trigger('submit');
                            } else {
                                confirmSmsCodeInput.val('');
                                confirmSmsCodeInput.focus();
                                codeContainer.text(confirmationCodeSubmitted);
                                error.show();
                            }
                        }
                    }
                });
            })
        }

        function init () {
            initModal();

            form.on('submit', (e) => {
                if (isUpdatedPhone()) {
                    e.preventDefault();
                    modal.modal({backdrop: 'static', keyboard: false});
                }
            });
        }

        init();
    })
})(jQuery);