$(document).ready(function () {
    $('#CGVModal').modal({
        backdrop: 'static',
        keyboard: false
    });

    $('#CGVcheckbox').on('change', function(e) {
        $('#CGVcheckbox').replaceWith('<div class="lds-dual-ring"></div>');
        if ($(this).is(':checked')) {
            $.ajax({
                method: 'GET',
                url: $('#CGVModal').attr('data-route'),
                success: (results) => {
                    $('#CGVModal').modal('hide')
                },
                error: function() {
                    $('#CGVModal').modal('hide')
                }
            });
        }
    });
});