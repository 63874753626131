$(document).ready(() => {
    const listenRequireConfirmation = () => {
        $('.js-required-confirmation').each((idx, el) => {
            $(el).on('click', (e) => {
                e.preventDefault();

                const actionButton = $(e.currentTarget);

                if (actionButton.attr("data-js-ajax-modal")) {
                    $('#confirmation-button').addClass('js-ajax-modal');
                } else {
                    $('#confirmation-button').removeClass('js-ajax-modal');
                }

                if (actionButton.attr("data-body")) {
                    $('#confirmation-modal .modal-body').text(actionButton.attr('data-body'));
                } else {
                    $('#confirmation-modal .modal-body').text('Êtes-vous sûr de vouloir effectuer cette action ?');
                }

                if (actionButton.is('a')) {
                    $('#confirmation-button').attr('href', actionButton.attr('href'));
                }

                if (actionButton.is('button')) {
                    $('#confirmation-button').on('click', (event) => {
                        event.preventDefault();

                        actionButton.closest('form').submit();
                    });
                }

                $('#confirmation-modal').modal('show');
            });
        });
    }

    listenRequireConfirmation();

    $('section.content, .control-sidebar').on('DOMSubtreeModified', () => {
        listenRequireConfirmation();
    })
});