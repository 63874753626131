(($) => {
    $(document).ready(() => {
        let modal = $('#js-pro-new-badge-modal');

        if (modal) {
            modal.modal({backdrop: 'static', keyboard: false});
            modal.on('hidden.bs.modal', () => {
                $.ajax({
                    async: true,
                    method: 'PUT',
                    url: modal.data('url'),
                    data: { proId : modal.data('pro-id') }
                });
            })
        }
    })
})(jQuery)