(($) => {
    $(document).ready(() => {
        let fileInput = $('input#add_realisation_media_id');
        let fileTextInput = $('input#add_realisation_media_text');
        let overWeightMessage = $('#js-consumer-realisation-over-weight-message');
        let fileTextInputPlaceholder = fileTextInput.attr('placeholder');

        function hideMessages () {
            let messages = $('form .alert-danger');

            messages.each((index, message) => {
                hideMessage($(message));
            })
        }

        function hideMessage (message) {
            if (!message.hasClass('d-none')) {
                message.addClass('d-none');
            }
        }

        function showMessage (message) {
            if (message.hasClass('d-none')) {
                message.removeClass('d-none');
            }
        }

        function listenPhotoUpload () {
            fileInput.on('change', () => {

                hideMessages();

                let filesNumber = fileInput.get(0).files.length;
                let label = fileInput.val().replace(/\\/g, '/').replace(/.*\//, '');
                let log = filesNumber > 1 ? filesNumber + ' fichiers sélectionnés' : label;

                if (getUploadSize(filesNumber) < 10240) {
                    fileTextInput.val(log);
                } else {
                    showMessage(overWeightMessage);
                    fileInput.val('');
                    fileTextInput.val(fileTextInputPlaceholder);
                }
            })
        }

        function listenClickTextInput() {
            fileTextInput.on('click', () => {
                fileInput.click();
            })
        }

        function getUploadSize (filesNumber) {
            let totalFilesSize = 0;

            for (let i = 0; i <= filesNumber - 1; i++) {
                totalFilesSize += Math.round(fileInput.get(0).files[i].size / 1024);
            }

            return totalFilesSize;
        }

        function init () {
            if (fileInput.length === 1 && fileTextInput.length === 1) {
                fileTextInput.val(fileTextInputPlaceholder);
                listenPhotoUpload();
                listenClickTextInput();
            }
        }

        init();
    })
})(jQuery);