const redirectPlayStore = () => {
    window.location = "https://play.google.com/store/apps/details?id=com.habitatpresto";
    dismissProposalDownload();
}

const dismissProposalDownload = () => {
    sessionStorage.setItem('habitatpresto:closeProposalDownloadModal', true);
    const proposalDownloadModalElement = document.getElementById('proposal_download_modal');
    if (proposalDownloadModalElement != null && proposalDownloadModalElement.value === '') {
        proposalDownloadModalElement.style.display = "none";
    }
}

(($) => {
    $(document).ready(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = userAgent.indexOf("android") > -1;
        const closeProposalDownloadModal = sessionStorage.getItem('habitatpresto:closeProposalDownloadModal');
        const proposalDownloadModalElement = document.getElementById('proposal_download_modal');

        if (proposalDownloadModalElement != null && proposalDownloadModalElement.value === '') {
            if (isAndroid && !closeProposalDownloadModal) {
                proposalDownloadModalElement.style.display = "block";
            } else {
                proposalDownloadModalElement.style.display = "none";
            }
        }
    })
})(jQuery);